<template>
    <div class="app-body monthCard">
        <div class="a-flex-rsbc a-ptb-6">
            <span class="a-fs-16 a-fw-b">人脸审核</span>
        </div>
        <el-card class="el-main">
            <le-search-form @reset="handlerRest" @search="handlerSearch" :canPackUp="true" id="leSearch">
                <le-input label="用户手机号" v-model="pageParam.params.phone" />
                <le-select-local-search label="审核状态" v-model="pageParam.params.auditStatus" :options="auditStatusDic" />
                <le-date-range 
                    ref="dateRange" 
                    label="申请时间" 
                    :minDate.sync="pageParam.params.startDate" 
                    :maxDate.sync="pageParam.params.endDate" />
            </le-search-form>
            <le-pagview ref="rfidPage" @setData="setTableData" :pageParam="pageParam" :isFixed="true" :tableRef="$refs.rfidPagelist">
                <el-table ref="rfidPagelist" :data="tableData" :highlight-current-row="true"  style="width: 100%" @filter-change='filterFun' v-sticky="{ top: 0, parent:'.el-card__body' }">
                    <el-table-column fixed="left" label="申请人手机号" min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.phone?row.phone:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="申请内容" fixed="left" min-width="120">
                        <template slot-scope="{ row }">
                            <el-image
                                style="width: 100px; height: 100px"
                                :src="row.faceUrl?row.faceUrl:''"
                                :preview-src-list="[row.faceUrl?row.faceUrl:'']"
                                fit="cover"></el-image>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="审核状态"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.auditStatus | initDic(auditStatusDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="审核人"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.auditPhone?row.auditPhone:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="申请时间"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.createTimeText?row.createTimeText:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="审核时间"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.auditTimeText?row.auditTimeText:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="审核结果"
                        fixed="right" 
                        column-key="auditResult"
                        :filter-multiple='false'
                        :filters="auditResultDic"
                        min-width="120">
                        <template slot-scope="{ row }">
                            <span>{{ row.auditResult | initDic(auditResultDic) }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column 
                        label="失败原因"
                        fixed="right" 
                        min-width="200">
                        <template slot-scope="{ row }">
                            <span>{{ row.msg?row.msg:'-' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="name" label="操作" width="60" fixed="right">
                        <template slot-scope="scope">
                            <el-tooltip class="item" effect="dark" content="审核" placement="top">
                                <img src="../../assets/icon/option-audit.png" class="a-wh-16" @click="audit(scope.row)" v-if="scope.row.auditStatus == 0" />
                            </el-tooltip>
                        </template>
                    </el-table-column>
                </el-table>
                <template slot="footerLabel" slot-scope="scope" >
                    <div class="a-flex-rfsc" v-if="scope.val != -1">
                        <span class="a-fs-12 a-c-normal">筛选出</span>
                        <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{scope.val}}</span>
                        <span class="a-fs-12 a-c-normal a-plr-10">条记录</span>
                    </div>
                </template>
            </le-pagview>
        </el-card>
        <rfid-audit ref="rfidAudit" @auditFinishi="auditFinishi"></rfid-audit>
    </div>
</template>

<script>
import util from '../../../src/utils/util'
import RfidAudit from './child/rfid-audit.vue'
    export default {
        components:{
            RfidAudit
        },
        data () {
            return {
                util: util,
                tableData: [],
                pageParam: {
                    url: this.$Config.apiUrl.facePage,
                    method: "post",
                    params: {
                        phone: '',
                        auditStatus: '0',
                        startDate: '',
                        endDate: '',
                        auditResult: ''
                    },
                    freshCtrl: 1,
                },
                auditResultDic: [],
                auditStatusDic: [],
            }
        },
        created () {
            this.$getDic('auditResult').then(res=>{ this.auditResultDic = res; })
            this.$getDic('auditStatus','select').then(res=>{ 
                this.auditStatusDic = res; 
                this.auditStatusDic.push({
                    value: '',
                    label: '全部'
                })
            })
        },
        activated () {
            this.pageParam.freshCtrl++;
        },  
        methods: {
            //获取列表
            setTableData(data) {
                this.tableData = data;
            },
            handlerRest() {
                this.pageParam.params = {
                    phone: '',
                    auditStatus: '0',
                    startDate: '',
                    endDate: '',
                    auditResult: ''
                };
                this.handlerSearch()
            },
            handlerSearch() {
                this.$refs['rfidPage'].pageNum = 1
                this.pageParam.freshCtrl++;
            },
            audit (datas) {
                this.$refs['rfidAudit'].id = datas.id
                this.$refs['rfidAudit'].dialogVisible = true
            },
            auditFinishi () {
                this.pageParam.freshCtrl++;
            },
            //条件筛选
            filterFun(value){
                for(var key in value){
                    if(key=='auditResult'){
                        if(value[key].length==0){
                            this.pageParam.params.auditResult = ''
                        }else{
                            this.pageParam.params.auditResult = value[key][0]
                        }
                    }
                }
                this.handlerSearch()
            },
        }
    }
</script>

<style lang="scss" scoped>
/deep/ .el-table__body-wrapper::-webkit-scrollbar {
    /*width: 0;宽度为0隐藏*/
    width: 0 !important;
    height: 0 !important;
}
/deep/ .el-table__fixed, /deep/ .el-table__fixed-right{
    height: 100% !important;
}
</style>