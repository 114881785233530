<template>
    <div>
        <el-dialog
            title="审核"
            :visible.sync="dialogVisible"
            :close-on-click-modal='false'
            :close-on-press-escape='false'
            width="500px">
            <el-form ref="form" :model="form" :rules="rules" label-position="left" label-width="100px">
                <el-form-item label="是否通过" prop="auditResult">
                    <el-radio :label="1" v-model="form.auditResult">通过</el-radio>
                    <el-radio :label="0" v-model="form.auditResult">拒绝</el-radio>
                </el-form-item>
                <el-form-item label="说明" prop="msg" v-if="form.auditResult == 0">
                    <el-input  
                        type="textarea"
                        :autosize="{ minRows: 4}" 
                        maxlength="200"
                        show-word-limit
                        v-model="form.msg" 
                        placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer">
                <el-button class="plainBtn" @click="dialogVisible = false">取消</el-button>
                <el-button type="primary"  @click="submit">确定</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                dialogVisible: false,
                form: {
                    auditResult: 0,
                    msg: '',
                },
                id: '',
                rules:{
                    auditResult: [{required: true, message:'请选择', trigger: 'change'}],
                    msg: [{required: true, message:'请填写说明', trigger: 'blur'}]
                }
            };
        },
        watch:{
            dialogVisible (val) {
                if(this.$refs['form']){
                    this.$refs['form'].resetFields()
                }
            },
        },
        methods:{
            submit () {
                this.$refs['form'].validate((valid) => {
                    if (valid) {
                        this.$Axios._post({
                            url: this.$Config.apiUrl.faceAudit,
                            method: "post",
                            params: {
                                id: this.id,
                                ...this.form
                            }
                        })
                        .then(res=>{
                            if (res.result.code == 0) {
                                this.$message.success('操作成功')
                                this.dialogVisible = false
                                this.$emit('auditFinishi')
                            }
                        })
                        .catch(err=>{

                        })
                    }
                })
            },
        }
    }
</script>